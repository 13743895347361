// noinspection ES6PreferShortImport

import { ErrorHandler, inject, Injectable, Injector, Provider } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from '@sentry/angular-ivy';
import { FLOORI_ENV } from '@floori-web/constants';
import { FlooriEnv } from '@floori-web/models';
import { Router } from '@angular/router';
import { DeviceService } from '@floori-web/tools';
import { AppFacade } from '../../../store/src/lib/facades';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly device = inject(DeviceService);
    private readonly env = inject<FlooriEnv>(FLOORI_ENV);
    private readonly chunkLoadErrorRegex = /chunkloaderror/i;
    private readonly sentryHandler?: SentryErrorHandler;

    constructor(private injector: Injector) {
        if (this.env.sentryKey) {
            Sentry.init({
                dsn: this.env.sentryKey,
                environment: this.env.envName,
                release: this.env.version,
                ignoreErrors: [
                    this.chunkLoadErrorRegex,
                    /loading chunk \d+ failed/i,
                    /0 Unknown Error/i,
                    /Failed to read the 'localStorage' property from 'Window'/i,
                    /The operation is insecure/i,
                    /config\.company\.languages is undefined/i,
                    /Product not found/i,
                    /No product with given criteria has been found/i,
                    /The provided ID is invalid/i,
                ],
                denyUrls: [/.*analytics.*/],
            });
            Sentry.configureScope(scope => {
                scope.setExtra('orientation', this.device.orientation);
            });
            this.sentryHandler = Sentry.createErrorHandler({});
        }
    }

    handleError(error: ErrorEvent): void {
        if (this.chunkLoadErrorRegex.test(error.message)) {
            this.injector.get(AppFacade)?.setError(true, 'chunkLoadErrorMessage');
        }

        if (this.env.sentryKey) {
            this.sentryHandler?.handleError(error);
            return;
        }

        console.error(error);
    }
}

export const flooriErrorHandler: Provider = {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
};

export const flooriTraceProvider: Provider = {
    provide: Sentry.TraceService,
    deps: [Router, FLOORI_ENV],
    useFactory: (router: Router, env: FlooriEnv) =>
        !!env.sentryKey ? new Sentry.TraceService(router) : null,
};
