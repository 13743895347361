import { InjectionToken } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HashMap, ProductFetchParams } from '@floori/models';
import { NavigationOptions } from './navigation-options';

export const FLOORI_NAVIGATION = new InjectionToken('FLOORI_NAVIGATION');

export interface NavigationProvider {
    appendQueryParam(paramName: string, value: string | null): void;

    dynamicProductParams(): [string, string, string] | null;

    goToRoom(routeParams: HashMap<string>, queryParams?: HttpParams): Promise<boolean>;

    goToRooms(): Promise<boolean>;

    getArLink(params: ProductFetchParams): string;

    goToAr(params: ProductFetchParams, target?: string): Promise<string>;

    goToContact(link: string): void;

    goToBookDemo(link: string, lang: string): void;

    goToUrl(link: string, options?: NavigationOptions): void;

    getUrl(): string;
}
