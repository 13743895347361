import { Inject, Injectable } from '@angular/core';
import {
    ConfigProvider,
    FLOORI_CONFIG_PROVIDER,
    FlooriWindow,
    NavigationOptions,
    NavigationProvider,
} from '@floori-web/models';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { HashMap, ProductFetchParams } from '@floori/models';
import { FLOORI_WINDOW, FlooriRoutes, flooriRoutes } from '@floori-web/constants';
import { filter, take, tap } from 'rxjs';

@Injectable()
export class NavigationService implements NavigationProvider {
    private readonly routes: Record<FlooriRoutes, string> = { ...flooriRoutes };

    constructor(
        private readonly router: Router,
        @Inject(FLOORI_CONFIG_PROVIDER) private readonly configProvider: ConfigProvider,
        @Inject(FLOORI_WINDOW) private readonly windowRef: FlooriWindow,
    ) {}

    appendQueryParam(paramName: string, value: string | null): void {
        if (!paramName) {
            return;
        }

        this.configProvider.companyConfig$
            .pipe(
                take(1),
                filter(config => !config?.epoxyView),
                tap(() => {
                    this.router.navigate([], {
                        queryParams: {
                            [paramName]: value,
                        },
                        queryParamsHandling: 'merge',
                    });
                }),
            )
            .subscribe();
    }

    goToRoom(routeParams: HashMap<string>): Promise<boolean> {
        const route = this.parsePath(this.routes.room, routeParams);
        const urlParsed = this.router.url.split('?');
        const queryParams = new URLSearchParams(urlParsed?.[1] || '');
        const params = new HttpParams({
            fromString: queryParams.toString(),
        });

        let tree = this.router
            .createUrlTree([urlParsed?.[0] || '/', ...route.split('/')])
            .toString();
        tree = params.keys().length ? `${tree}?${queryParams.toString()}` : tree;

        return this.router.navigateByUrl(tree);
    }

    goToRooms(): Promise<boolean> {
        let route = this.parsePath(this.routes.rooms);
        const hasPreview = this.router.url.indexOf(flooriRoutes.preview) > -1;

        if (hasPreview) {
            route = this.router
                .createUrlTree([flooriRoutes.preview, this.getPreviewToken()])
                ?.toString();
        }

        return this.router.navigate([route], {
            queryParams: {},
            queryParamsHandling: 'merge',
        });
    }

    getArLink(params: ProductFetchParams): string {
        if (!params?.sku && !params?.extId && !params?.productId) {
            return '';
        }

        const validKey = Object.keys(params).find(key => !!params[key as keyof ProductFetchParams]);
        if (!validKey) {
            return '';
        }
        const paramValue = params[validKey as keyof ProductFetchParams];
        const hasPreview = this.isPreview();
        const segments = this.router.createUrlTree([this.router.url])?.root?.children?.['primary']
            ?.segments;
        const route = hasPreview ? segments.slice(0, 2).join('/') : '';
        const link = `${this.windowRef.location.origin}/${route}/${flooriRoutes.ar}?${validKey}=${paramValue}`;

        if (!params.variantId) {
            return link;
        }

        return `${link}&variantId=${params.variantId}`;
    }

    goToAr(params: ProductFetchParams, target: string = '_blank'): Promise<string> {
        if (!params.sku && !params?.extId && !params?.productId) {
            return Promise.resolve('');
        }

        const arLink = this.getArLink(params);
        if (!arLink) {
            return Promise.resolve('');
        }

        if (target === '_blank') {
            this.windowRef.open(arLink, target);
            return Promise.resolve(arLink);
        }

        this.router.navigateByUrl(arLink);
        return Promise.resolve(arLink);
    }

    goToContact(link: string): void {
        this.windowRef.location.href = `mailto:${link}`;
    }

    goToBookDemo(link: string, lang: string): void {
        link = link.replace('/LANG', lang);
        this.windowRef.open(link, '_blank');
    }

    goToUrl(link: string, options?: NavigationOptions): void {
        const https = 'https://';
        const target = options?.target ?? '_blank';

        if (!link.startsWith(https) && !link.startsWith('http://')) {
            link = `${https}${link}`;
        }

        if (options?.addSource) {
            if (link.includes('?')) {
                link = `${link}&utm_source=floori`;
            } else {
                link = `${link}?utm_source=floori`;
            }
        }

        this.windowRef.open(link, target);
    }

    getUrl(): string {
        return this.router.url;
    }

    dynamicProductParams(): [string, string, string] | null {
        const paramsMap = this.router.routerState.snapshot.root.queryParamMap;
        const width = paramsMap.get('width') || '';
        const height = paramsMap.get('height') || '';
        const url = paramsMap.get('url') || '';
        if (!width || !height || !url) {
            return null;
        }

        return [width, height, url];
    }

    protected parsePath(path: string, pathParts: HashMap<string> = {}): string {
        let retUrl = path;
        Object.keys(pathParts || {}).forEach(key => {
            const keyPart = `:${key}`;
            if (key && !!pathParts[key] && pathParts?.[key]) {
                retUrl = retUrl.replace(keyPart, pathParts[key]?.toString());
            } else {
                retUrl = retUrl.replace(`/${keyPart}`, '');
            }
        });
        return retUrl;
    }

    protected getPreviewToken(): string {
        return this.router.createUrlTree([this.router.url])?.root?.children?.['primary']
            ?.segments[1]?.path;
    }

    private isPreview(): boolean {
        const firstSegment = this.router.createUrlTree([this.router.url])?.root?.children?.[
            'primary'
        ]?.segments[0]?.path;
        return firstSegment === flooriRoutes.preview;
    }
}
