import { FieldTranslation, ProductDto, TreeCatalogDto } from '@floori/models';
import { ProductTextureGenerationStatus } from 'libs/shared/models/src/lib/product/product-status';
import { FilterResultBase, FilterResultType } from './product-filter';

export type ProductViewerType = Product | ProductCatalog;

export class Product extends FilterResultBase {
    readonly name: FieldTranslation;
    readonly visibleOnLite: boolean;
    readonly parentId?: string;
    readonly price?: string;
    readonly status?: ProductTextureGenerationStatus;
    readonly metadata?: Record<string, unknown>;

    constructor(productDto?: Partial<ProductDto>, parentId?: string) {
        super(productDto?.id || '', productDto?.image, productDto?.rgbaColor);
        this.type = FilterResultType.product;
        this.name = productDto?.name || { default: '' };
        this.visibleOnLite = !!productDto?.visibleOnLite;
        this.parentId = productDto?.parentId || parentId;
        this.price = productDto?.price || '';
        this.status = productDto?.status;
        this.metadata = productDto?.metadata;
    }
}

export class ProductCatalog extends FilterResultBase {
    readonly name: FieldTranslation;
    readonly description: string;
    readonly price?: string;
    readonly metadata?: Record<string, unknown>;

    constructor(catalogDto: Partial<TreeCatalogDto>) {
        super(catalogDto?.id || '', catalogDto.image);
        this.type = FilterResultType.catalog;
        this.name = { default: catalogDto?.name || '' };
        this.description = catalogDto?.description || '';
        this.metadata = catalogDto?.metadata;
    }
}
